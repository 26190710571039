import React, {useState, useEffect, useRef} from 'react';
import { WebSocketProvider, useWebSocket } from './context/WebSocketContext.jsx';
import './LolSpec.css';
import {Table} from './components/Table.jsx';
import { SearchFilter } from './components/SearchFilter.jsx';

const LolSpecContent = () => {
    const { error, fakeMessage } = useWebSocket();
    const [summoners, setSummoners] = useState([]);
    const [fakeMsgData, setFakeMsgData] = useState({});
    const api_request = '/api/league_standing/na1/RANKED_SOLO_5x5/CHALLENGER';

    const getHighestLeaguePoints = (entries) => {
        return entries.sort((a, b) => {
            const { leaguePoints : aLeaguePoints } = a;
            const { leaguePoints : bLeaguePoints } = b;
            return aLeaguePoints < bLeaguePoints ? 1 : -1;
        })[0];
    };

    const getChallengers = async () => {
        try {
            const response = await fetch(api_request);
            const playerListFromApi = await response.json();
            try{
                const {players : summoners} = playerListFromApi;
                setFakeMsgData(getHighestLeaguePoints(summoners));
                setSummoners(summoners);
            } catch (error) {
                console.error('Error setting players:', error);
            }
        } catch (error) {
            console.error('Error handling button click:', error);
        }
    };
    const contrivedUpdate = async () =>  {
        fakeMsgData.leaguePoints += Math.floor(Math.floor(Math.random() * 100));
        console.log('new league points:', fakeMsgData.leaguePoints);
        fakeMessage('summoner', 'summonerId', [fakeMsgData]);
    }
    const tableInfo = {
        columnNames: ['summonerId', 'leaguePoints'],
        key: 'summonerId',
        initialSortKey: 'leaguePoints',
        initialSortOrder: 'desc',
        initialRowsPerPage: 10,
        liveUpdateResource: {
            dataType: 'summoner',
        }
    }

    const options = ['PLAYERS', 'MATCHES', 'MATCHUPS']

    return (
        <div className="LolSpec">
            <h1>TEST OF SEARCH FILTER</h1>
            <SearchFilter options={options}></SearchFilter>
            <button onClick={getChallengers}>Get challenger players</button>
            {summoners.length > 0 && <Table tableData={summoners} tableInfo={tableInfo}/>}
        </div>
    );
};

const LolSpec = () => {
    return (
        <WebSocketProvider>
            <LolSpecContent/>
        </WebSocketProvider>
    );
};

export default LolSpec;

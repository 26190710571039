import React, {useEffect, useRef, useState} from 'react';
import { motion } from 'framer-motion';
import { MorphingSelector } from './MorphingSelector';
import './css/SearchFilter.css';

const SearchFilter = ({ options }) => {
    const [selectedIndex, setSelectedIndex] = useState(null);
    const buttonsRef = useRef(options.map(() => React.createRef()));
    const selfRef = useRef(null);


    const handleOptionClick = (index) => {
        setSelectedIndex(index);
    };

    const getSelectorData = () => {
        if (selectedIndex !== null) {
            const buttonRect = buttonsRef.current[selectedIndex].current.getBoundingClientRect();
            const searchFilterRect = selfRef.current.getBoundingClientRect();
            return {
                left: buttonRect.left - searchFilterRect.left,
                top: buttonRect.top - searchFilterRect.top ,
                width: buttonRect.width,
                height: buttonRect.height,
            };
        } else {
            return {
                left: 0,
                top: 0,
                width: 0,
                height: 0,
            };
        }
    };

    return (
        <div
            className="search-filter"
            ref={selfRef}
        >
            {options.map((option, index) => (
                <motion.button
                    key={index}
                    ref={buttonsRef.current[index]}
                    onClick={() => handleOptionClick(index)}
                    whileHover={{ color: '#f8e71c', backgroundColor: '#1cb2f8' }}
                    transition={{ type: 'tween', stiffness: 300 }}
                >
                    {option}
                </motion.button>
            ))}
            <MorphingSelector selectorData={getSelectorData()}/>
        </div>
    );
};

export { SearchFilter };

import React, {useEffect, useRef, useState} from 'react';
import {motion, useAnimate } from 'framer-motion';
import './css/MorphingSelector.css';

/**
 * Selector that highlights the selected option with a morphing animation
 * @param {Object} selectorData - New position and size of the highlight, relative to the parent element
 * @param parentRef
 * @returns {Element}
 * @constructor
 */
const MorphingSelector = ({ selectorData}) => {
    const [scope, animate] = useAnimate();
    const prevRef = useRef(selectorData);

    useEffect(() => {
        const start = {
            left : prevRef.current.left,
            top : prevRef.current.top,
            width : prevRef.current.width,
            height : prevRef.current.height,
            center : {
                x : prevRef.current.left + (prevRef.current.width / 2),
                y : prevRef.current.top + (prevRef.current.height / 2),
            }
        }
        const end = {
            left : selectorData.left,
            top : selectorData.top,
            width : selectorData.width,
            height : selectorData.height,
            center : {
                x : selectorData.left + (selectorData.width / 2),
                y : selectorData.top + (selectorData.height / 2),
            }
        }
        const dx = end.center.x - start.center.x;
        const dy = end.center.y - start.center.y;

        animate(scope.current, {
            left: [start.left, (start.left + start.width / 2) + (dx / 2) - 15, end.left],
            top: [start.top, (start.top + start.height / 2) + (dy / 2) - 15, end.top],
            width: [null, 30, selectorData.width],
            height: [null, 30, selectorData.height],
            borderRadius: ["6%", "50%", "6%"],
            transition: {
                ease: "linear",
                duration: 2,
                times: [0, 0.5, 1],
            },
        }).then(() => console.log('Animation complete'))
            .catch(error => console.error('Animation error:', error));

        prevRef.current = selectorData;
    }, [selectorData]);

    return (
        <motion.div
            className="morphing-selector"
            ref={scope}
        />
    );
};

export { MorphingSelector };

import React, {useState, useEffect, useCallback, useRef} from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { useWebSocket } from '../context/WebSocketContext.jsx';
import './css/Table.css';
import './css/TableRow.css';
import './css/TableCell.css';

//region Type Definitions

/**
 * @typedef  {Object} TableInfo             - Metadata about the table and how it should function.
 * @property {string} tableName             - The name of the table, displayed to user (probably).
 * @property {string} tableDescription      - Brief description of the table.
 * @property {string[]} columnNames         - The columns of the table.
 * @property {string} initialEntriesPerPage - The number of entries to show per page.
 * @property {string} initialSortOrder      - Either 'asc' or 'desc', order to sort the sortKey column by.
 * @property {string} initialSortKey        - The initial column to sort by.
 * @property {string} key                   - The column guaranteed to be unique for each entry
 * @property {Object} liveUpdateResource    - Contains info about how the table should be updated by server (or not)
 */

/**
 * @typedef  {Object} rowInfo               - Contains metadata about the row.
 * @property {number} pageNumber            - The page number the row is on.
 * @property {number|null} prevPageNumber   - The page number the row was on before the last sort.
 * @property {number} pageIndex             - The index of the row on the current page.
 * @property {number|null} prevPageIndex    - The index of the row on the previous page.
 */

//endregion

// region private components
/**
 * @param content
 * @param {function} registerAnimation
 * @param {function} unregisterAnimation
 * @returns {Element}
 * @constructor
 */
const TableCell = React.memo(({ content, registerAnimation, unregisterAnimation }) => {
    const [displayContent, setDisplayContent] = useState(content);

    useEffect(() => {
        if (content !== displayContent) {
            registerAnimation();
            setDisplayContent(content);
            setTimeout(() => {
                unregisterAnimation();
            }, 500); // Match the CSS animation duration
        }
    }, [content, displayContent, registerAnimation, unregisterAnimation]);

    return (
        <td>
            <div className="cell-content-wrapper">
                <TransitionGroup>
                    <CSSTransition
                        key={displayContent}
                        classNames="cell-animation"
                        timeout={500}
                    >
                        <div className="cell-content">{displayContent}</div>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        </td>
    );
});

TableCell.propTypes = {
    content: PropTypes.any.isRequired,
    registerAnimation: PropTypes.func.isRequired,
    unregisterAnimation: PropTypes.func.isRequired,
};


/**
 * @param {[integer|string|Element]} rowData
 * @param {rowInfo} rowInfo
 * @param {function} registerAnimation
 * @param {function} unregisterAnimation
 * @returns {Element}
 * @constructor
 */
const TableRow = React.memo(({rowData, rowInfo, registerAnimation, unregisterAnimation }) => {
    console.log('rendering TableRow')
    const [className, setClassName] = useState('table-row');

    const { pageNumber, prevPageNumber, pageIndex, prevPageIndex } = rowInfo;

    const indexChange = prevPageIndex - pageIndex || 0;
    const wasOnPrevPage = !!prevPageNumber && pageNumber === prevPageNumber;

    useEffect(() => {
        setClassName(wasOnPrevPage ? 'table-row slide' : 'table-row fade');
    }, [wasOnPrevPage]);

    useEffect(() => {
        if (className.includes('slide') || className.includes('fade')) {
            registerAnimation();
            const timeoutId = setTimeout(() => {
                unregisterAnimation();
            }, 500); // Match the CSS animation duration
            return () => clearTimeout(timeoutId);
        }
    }, [className, registerAnimation, unregisterAnimation]);

    return (
        <tr className={className} style={{ '--start-translateY': `${indexChange * 100}%` }}>
            {rowData.map((cellContent, index) => {
                return (
                    <TableCell key={index}
                               content={cellContent}
                               registerAnimation={registerAnimation}
                               unregisterAnimation={unregisterAnimation}/>
                );
            })}
        </tr>
    );
});

TableRow.propTypes = {
    rowData: PropTypes.arrayOf(PropTypes.any).isRequired,
    rowInfo: PropTypes.shape({
        pageNumber: PropTypes.number.isRequired,
        prevPageNumber: PropTypes.number, // Allow null
        pageIndex: PropTypes.number.isRequired,
        prevPageIndex: PropTypes.number, // Allow null
    }).isRequired,
    registerAnimation: PropTypes.func.isRequired,
    unregisterAnimation: PropTypes.func.isRequired,
};

// endregion private components

/**
 * @param {Object[]} tableData - array of objects that have (at minimum) properties for each column
 * @param {TableInfo} tableInfo - object containing metadata about the table.
 * @returns {JSX.Element} - rendered table
 * @constructor Table
 */
const Table = ({ tableData, tableInfo }) => {
    console.log('rendering Table')
    const { subscribe, unsubscribe } = useWebSocket();
    /** @type {array<Object>} - sorted list of rows (init unsorted, triggers useEffect on initial render) */
    const [sortedTableData, setSortedTableData] = useState(
        tableData.map((row) => ({
            rowData: row,
            rowInfo: { pageNumber: 1, prevPageNumber: null, pageIndex: 0, prevPageIndex: null } }))
    );

    //region Animation handling
    const animatingRowsRef = useRef(0);

    const registerAnimatingRow = useCallback(() => {
        animatingRowsRef.current += 1;
        console.log('currently ',  animatingRowsRef.current, ' animating rows')
    }, []);

    const unregisterAnimatingRow = useCallback(() => {
        animatingRowsRef.current -= 1;
        console.log('currently ',  animatingRowsRef.current, ' animating rows')
    }, []);
    //endregion Animation handling

    //region Table setup
    const {
        key,
        columnNames,
        initialSortKey,
        initialSortOrder,
        initialRowsPerPage,
        liveUpdateResource,
    } = tableInfo;

    const { dataType } = liveUpdateResource;

    if (!initialSortKey || !initialSortOrder || !columnNames || !key) {
        console.log('tableInfo:', tableInfo)
        throw new Error('tableInfo props not set correctly');
    }

    /** @type {string} - name of the column used to sort the entries based on */
    const [sortKey, setSortKey] = useState(initialSortKey);
    /** @type {string} - either 'asc' or 'desc', no other valid state */
    const [sortOrder, setSortOrder] = useState(initialSortOrder); // 'asc' or 'desc'
    /** @type {number} - current page of paginated table */
    const [currentPage, setCurrentPage] = useState(1);
    /** @type {number} - how many entries maximum should be shown per page */
    const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
    /** @type {array<Object>} - sorted list of rows, from before last sort */
    const [prevRowsPerPage, setPrevRowsPerPage] = useState(rowsPerPage);
    //endregion Table setup

    const sortTableData = (data) => {
        const newSortedTableData = data.map((row) => ({
            ...row,
            rowInfo: {
                ...row.rowInfo,
                prevPageNumber: row.rowInfo.pageNumber,
                prevPageIndex: row.rowInfo.pageIndex,
            }
        }));
        newSortedTableData.sort((a, b) => {
            const aVal = a.rowData[sortKey];
            const bVal = b.rowData[sortKey];
            if (sortOrder === 'asc') {
                return aVal > bVal ? 1 : -1;
            }
            return aVal < bVal ? 1 : -1;
        });
        newSortedTableData.forEach((row, ind) => {
            row.rowInfo.pageNumber = Math.floor(ind / rowsPerPage) + 1;
            row.rowInfo.pageIndex = ind % rowsPerPage;
        });
        return newSortedTableData;
    };

    const receiveUpdate = useCallback((updatedSummoners) => {
        setSortedTableData((prevSortedTableData) => {
            const newSortedTableData = [...prevSortedTableData];
            updatedSummoners.forEach((updatedSummoner) =>{
                const index = newSortedTableData.findIndex((row) => row.rowData[key] === updatedSummoner[key]);
                if (index !== -1) {  // if the summoner is already in the table
                    newSortedTableData[index].rowData = updatedSummoner;
                } else { // if the summoner is not in the table
                    newSortedTableData.push({ rowData : updatedSummoner, rowInfo : { pageNumber: 1, prevPageNumber: null, pageIndex: 0, prevPageIndex: null }, });
                }
            });
            return sortTableData(newSortedTableData);
        });
    }, [key]);

    useEffect(() => {
        //subscribe to all the keys in the table
        subscribe(dataType, sortedTableData.map(row => row.rowData[key]), receiveUpdate);

        return () => {
            unsubscribe(dataType, sortedTableData.map(row => row.rowData[key]), receiveUpdate);
        };
    }, []);

    /**
     * when the user changes how many entries are shown per page, reset the current page to 1 to avoid complication
     */
    useEffect(() => {
        setCurrentPage(1);
    } , [rowsPerPage]);

    useEffect(() => {
        if (rowsPerPage !== prevRowsPerPage) {
            setPrevRowsPerPage(rowsPerPage);
        }
    }, [prevRowsPerPage, rowsPerPage]);

    // Sort entries whenever sortKey, sortOrder, or entriesPerPage changes
    useEffect(() => {
        setSortedTableData((oldSortedData) => sortTableData(oldSortedData));
    }, [sortKey, sortOrder, rowsPerPage]);

    const showOnlyTopTenEntries = () => {
        setSortOrder('desc');
        setSortedTableData(sortedTableData.slice(0, 10));
        setRowsPerPage(10);
        setCurrentPage(1);
    };

    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = Math.min(startIndex + rowsPerPage, sortedTableData.length);
    const rowsOnPage = sortedTableData.slice(startIndex, endIndex);
    const isAnimating = animatingRowsRef > 0;

    return (
        <div>
            <table className="table">
                <thead>
                    <tr>
                        {columnNames.map((columnName) => (
                            <th
                                key={columnName}
                                onClick={ isAnimating ? null : () => {
                                    if (columnName === sortKey) {
                                        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
                                    } else {
                                        setSortKey(columnName);
                                        setSortOrder('desc');
                                    }
                                }
                            }
                                className={isAnimating ? 'disabled' : ''}>
                                {columnName}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                {rowsOnPage.map((row) => (
                        <TableRow
                            key={row.rowData[key]}
                            rowData={columnNames.map(name =>row.rowData[name])}
                            rowInfo={row.rowInfo}
                            registerAnimation={registerAnimatingRow}
                            unregisterAnimation={unregisterAnimatingRow}
                        />
                    ))}
                </tbody>
            </table>
            <div>
                <button onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}>
                    Previous
                </button>
                <button onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={startIndex + rowsPerPage >= sortedTableData.length}>
                    Next
                </button>
            </div>
            <div>
                <label>
                    Entries per page:
                    <select value={rowsPerPage} onChange={e => setRowsPerPage(Number(e.target.value))}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                </label>
            </div>
            <div>
                <button onClick={showOnlyTopTenEntries}>
                    Show Only 10 Entries
                </button>
            </div>
        </div>
    );
};

Table.propTypes = {
        tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
        tableInfo: PropTypes.shape({
        columnNames: PropTypes.arrayOf(PropTypes.string).isRequired,
        key: PropTypes.string.isRequired,
        initialSortKey: PropTypes.string,
        initialSortOrder: PropTypes.oneOf(['asc', 'desc']),
    }).isRequired,
};

export {
    Table,
};

// context/WebSocketContext.jsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';

const SERVER_ADDRESS = import.meta.env.VITE_SERVER_ADDRESS;

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const [summonerSocket, setSummonerSocket] = useState(null);
    const [subscribers, setSubscribers] = useState({});

    const fakeMessage = (namespace, subKey, data) => {
        for (const el of data) {
            const key = el[subKey];
            subscribers[namespace][key].forEach(callback => {
                callback([el]);
            });
        }
    }

    // dataType : summoner (namespace to sub to)
    // channels : [summonerId1, summonerId2, ...]
    const subscribe = (dataType, channels, callback) => {
        const sockMap = {
            'summoner': summonerSocket,
        }
        const socket = sockMap[dataType];
        socket.emit('subscribe', channels);
        setSubscribers((prevSubscribers) => {
            const newSubscribers = {...prevSubscribers};
            for (const channel of channels) {
                if (!newSubscribers[dataType]) {
                    newSubscribers[dataType] = {};
                }
                if (!newSubscribers[dataType][channel]) {
                    newSubscribers[dataType][channel] = [];
                }
                newSubscribers[dataType][channel].push(callback);
            }
            return newSubscribers;
        });
    }

    const unsubscribe = (dataType, channels, callback) => {
        const sockMap = {
            'summoner': summonerSocket,
        }
        sockMap[dataType].emit('unsubscribe', channels);
        setSubscribers(prevSubscribers => {
            const newSubscribers = {...prevSubscribers};
            for (const channel of channels) {
                if (newSubscribers[dataType] && newSubscribers[dataType][channel]) {
                    const callbackIndex = newSubscribers[dataType][channel].findIndex((cb) => cb === callback);
                    if (callbackIndex !== -1) {
                        newSubscribers[dataType][channel].splice(callbackIndex, 1);
                    }
                }
            }
            return newSubscribers;
        });
    }

    useEffect(() => {
        const newSocket = io(SERVER_ADDRESS);
        const newSummonerUpdateSocket = io('/summoner');

        newSummonerUpdateSocket.on('update', (data) => {
            for (const summoner of data) {
                const { summonerId } = summoner;
                if (subscribers['summoner'] && subscribers['summoner'][summonerId]) {
                    subscribers['summoner'][summonerId].forEach(callback => {
                        callback([summoner]);
                    });
                }
            }
        });

        setSocket(newSocket);
        setSummonerSocket(newSummonerUpdateSocket);
        return () => {
            newSocket.close();
            newSummonerUpdateSocket.close();
        };
    }, [subscribers]);

    return (
        <WebSocketContext.Provider value={{socket, subscribe, unsubscribe, fakeMessage}}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => {
    return useContext(WebSocketContext);
};
